import React from "react";
import { EasterEggContext } from "../context/EasterEggProvider";

function Profile(props) {
  const { state: easterEgg } = React.useContext(EasterEggContext);
  return (
    <img
      src={!easterEgg ? props.avatar : props.easterEggAvatar}
      alt="Avatar"
      className={`w-80 rounded-full border-6 border-white shadow-md flex-shrink-0 ${
        props.easterEgg ? "animate-spin" : ""
      }`}
    />
  );
}

export default Profile;
