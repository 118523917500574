import { useEffect, useContext, useState } from "react";
import Container from "./components/Container";
import { useKonami } from "react-konami-code";
import picture from "./assets/profile.jpg";
import logo from "./assets/logo.png";
import music from "./assets/cv.mp3";
import Skills from "./components/Skills";
import useAudio from "./hooks/useAudio";
import CardProfile from "./components/CardProfile";
import Experiences from "./components/Experiences";
import Toggle from "./components/Toggle";
import { EasterEggContext } from "./context/EasterEggProvider";
import { ThemeContext } from "./context/ThemeProvider";
import Combo from "./components/Combo";
function App() {
  const { setState } = useContext(EasterEggContext);
  const { setTheme } = useContext(ThemeContext);
  const [playing, toggle] = useAudio(music);
  const [imageKey, setImageKey] = useState([]);
  const onKonami = () => {
    toggle();
    setState(true);
    setTheme("dark");
    setImageKey(["clear"]);
  };
  useKonami(onKonami);

  useEffect(() => {
    if (!playing) {
      setState(false);
      setTheme("light");
      setImageKey(["clear"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing]);
  const handleKeyDown = (event) => {
    const { key } = event;
    const rand = Math.random * 5;
    setImageKey([key, rand]);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Container>
      <Toggle />
      <Combo imageKey={imageKey} />
      <div className="absolute w-full min-h-screen">
        <div className="absolute z-0 top-0 w-full h-1/2 bg-gradient-to-tr from-light-black dark:from-light-black to-gray-900 dark:to-gray-700 transition-all"></div>
      </div>
      <CardProfile avatar={picture} logo={logo} />
      <Skills />
      <Experiences />
      <div className="flex flex-row">
        <p className="text-xs text-gray-300 self-center lg:mt-auto lg:ml-auto mt-2">
          site design © 2021 Matheus Pereira
        </p>
      </div>
    </Container>
  );
}

export default App;
