import React, { useEffect, useState } from "react";
import ComboImage from "./ComboImage";
import { keys } from "./keys";

const Combo = (props) => {
  const [comboImages, setComboImages] = useState([]);

  const { imageKey } = props;

  useEffect(() => {
    const key = imageKey[0];
    if (key === "clear") setComboImages([]);
    if (keys[key]) {
      let newCombo = [...comboImages];
      newCombo.push(key);
      setComboImages(newCombo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageKey]);

  useEffect(() => {
    if (comboImages.length >= 11) {
      setComboImages([]);
    }
  }, [comboImages]);

  return (
    <div className="fixed z-30">
      <div className="h-screen w-screen">
        {comboImages.length > 2 && (
          <div className="absolute top-almost left-1/2 transform -translate-x-2/4 -translate-y-2/4">
            <div className="bg-light-black opacity-80 px-10 py-5 rounded-full flex flex-row items-center justify-center">
              {comboImages.map((c, i) => (
                <ComboImage pressed={c} key={i} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Combo;
