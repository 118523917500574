import React from "react";

export const EasterEggContext = React.createContext();

export const EasterEggProvider = ({ children }) => {
  const [state, setState] = React.useState(false);

  return (
    <EasterEggContext.Provider value={{ state, setState }}>
      {children}
    </EasterEggContext.Provider>
  );
};
