import React from "react";

// import { Container } from './styles';

function Container(props) {
  return (
    <div className={`antialiased relative text-gray-600 dark:bg-light-black`}>
      {props.children}
    </div>
  );
}

export default Container;
