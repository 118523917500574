import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "./context/ThemeProvider";
import { EasterEggProvider } from "./context/EasterEggProvider";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <EasterEggProvider>
        <App />
      </EasterEggProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
