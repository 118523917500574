import React from "react";
import Experience from "./Experience";
// import { Container } from './styles';
import oowlish from "../assets/oowlish.svg";
import iconic from "../assets/iconic-blockchain.png";
import eaware from "../assets/eaware.png";
import agenciou from "../assets/agenciou.png";
import target from "../assets/target.jpg";
import lacador from "../assets/lacador.jpg";
function Experiences() {
  return (
    <section className="pt-20 pb-28 px-8 dark:bg-gray-600">
      <div className="max-w-6xl mx-auto">
        <div className="text-center">
          <h2 className="text-5xl text-gray-800 font-bold dark:text-gray-300">
            Experiences
          </h2>
        </div>
        <div className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-14">
          <Experience
            company={"Oowlish"}
            job="Sr Frontend Eng."
            period="Oct 2021 – present"
            className={"lg:col-span-3"}
            img={oowlish}
          >
            Working remotely to an international client as a Senior Frontend
            Engineer, focusing in upgrading and developing new features using
            mostly ReactJS and React Native.
          </Experience>
          <Experience
            company={"Iconic Blockchain"}
            job="Software Eng."
            period="May 2019 – Oct 2021"
            className={"lg:col-span-2"}
            img={iconic}
          >
            Architecture and creation of microservices and platforms in the
            cryptocurrency area, working mostly with NodeJS in the back-end,
            React as front and React Native as mobile framework. Maintenance of
            current services, creating and structuring new ways to communicate
            with customers via ChatBots,Sms,Messenger and etc, using mainly
            NodeJS as backend.
          </Experience>
          <Experience
            company={"E-AWARE Tech."}
            job="Frontend Eng."
            period="Jul 2017 – Sep 2018"
            img={eaware}
          >
            Responsible for create,structure and implant the core font-end
            application with ReactJS and Redux. Using a series of Restful APIs
            to manage the datas.
          </Experience>
          <Experience
            company={"Agenciou!"}
            job="Frontend Eng."
            period="Mar 2018 - Apr 2018"
            img={agenciou}
          >
            Build and implant a dynamic form in ReactJS using Redux, reading
            data from an API and molding the form accordingly by the actions
            taken by the user
          </Experience>
          <Experience
            company={"TargetIT"}
            job="Fullstack Dev."
            period="Jan 2017 - Jul 2017"
            img={target}
          >
            Responsible maintenence and upgrade on their application, mostly in
            PHP and Java. Create web services that communicates with city halls
            retrieving and sending data.
          </Experience>
          <Experience
            company={"Laçador de Ofertas"}
            job="Fullstack Dev."
            period="Oct 2015 - Dec 2016"
            img={lacador}
          >
            Creation and maintenance of their website and the constant upgrade
            of an CMS that provides data for the website; Using PHP with
            CakePHP.
          </Experience>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
