import React from "react";

// import { Container } from './styles';

function Experience({ company, job, period, children, span, img, className }) {
  return (
    <div className={`bg-white rounded-md shadow-md ${className}`}>
      <img src={img} alt={company} className="p-2 object-contain w-full h-80" />
      <div className="p-8">
        <div className="flex flex-col md:flex-row  md:items-center">
          <h3 className="font-bold text-lg md:text-2xl">{job}</h3>
          <p className="md:ml-auto ml-0 text-xs text-gray-400">{period}</p>
        </div>
        <h2>@{company}</h2>
        <p className="pt-3">{children}</p>
      </div>
    </div>
  );
}

export default Experience;
