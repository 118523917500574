import React from "react";
import { IoLogoGithub, IoLogoLinkedin, IoMail } from "react-icons/io5";
import Avatar from "./Avatar";

import { EasterEggContext } from "../context/EasterEggProvider";

function CardProfile(props) {
  const { state: easterEgg } = React.useContext(EasterEggContext);
  return (
    <section className="relative z-30 flex min-h-screen h-auto justify-center items-center shadow-sm">
      <div className="relative max-w-4xl">
        <div className="absolute z-30 inset-0 bg-gradient-to-r from-primary dark:from-red-500 to-secondary dark:to-light-black shadow-md transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 rounded-md"></div>
        <div className="relative z-40 bg-white dark:bg-black md:flex justify-between p-12 shadow-lg rounded-lg w-full max-w-4xl">
          <div className="sm:flex flex-col justify-between space-y-6 py-6 md:pr-10">
            <div>
              <p className="text-lg dark:text-gray-200">Hello there, I'm</p>
              <h1
                className={`${
                  !easterEgg ? "text-5xl" : "text-4xl"
                } font-bold text-gray-800 dark:text-white`}
              >
                {!easterEgg ? "Matheus Pereira" : 'Matheus "zigoss" Pereira'}
              </h1>
            </div>
            <p className="text-xl leading-relaxed dark:text-gray-200">
              Frontend engineer based in Brazil, who loves technology and music.{" "}
              <br />
              {easterEgg && "Loves gaming, geek things and NFL."}
            </p>
            <div className="inline-flex space-x-4 text-2xl dark:text-gray-200">
              <a
                href="https://github.com/math-eusp"
                target="_blank"
                rel="noreferrer"
              >
                <IoLogoGithub />
              </a>
              <a
                href="https://www.linkedin.com/in/math-eusp/"
                target="_blank"
                rel="noreferrer"
              >
                <IoLogoLinkedin />
              </a>
              <a
                href="mailto:contato@matheusp.dev"
                target="_blank"
                rel="noreferrer"
              >
                <IoMail />
              </a>
            </div>
          </div>
          {props.avatar ? (
            <Avatar
              avatar={props.avatar}
              easterEgg={easterEgg}
              easterEggAvatar={props.logo}
            />
          ) : null}
        </div>
      </div>
    </section>
  );
}

export default CardProfile;
