import React from "react";
import { EasterEggContext } from "../context/EasterEggProvider";

// import { Container } from './styles';

function Card({ children }) {
  const { state: easterEgg } = React.useContext(EasterEggContext);

  const getBounce = () => {
    const rand = Math.floor(Math.random() * 3);
    switch (rand) {
      case 1:
        return "animate-bounce-slow";
      case 2:
        return "animate-bounce-medium";
      case 3:
        return "animate-bounce-fast";
      default:
        return "animate-bounce";
    }
  };
  return (
    <div
      className={`relative ${
        easterEgg ? "transition transform " + getBounce() : ""
      }`}
    >
      <div className="absolute z-0 inset-0 bg-gradient-to-r from-primary dark:from-red-500 to-secondary dark:to-light-black shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-lg"></div>
      <div className="relative z-10 bg-white dark:bg-light-black h-full rounded-md shadow-md px-10 py-12 -skew-y-0 sm:skew-y-0 sm:-rotate-0">
        {children}
      </div>
    </div>
  );
}

export default Card;
