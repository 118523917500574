import React from "react";
import Card from "./Card";
import { CgIfDesign } from "react-icons/cg";
import { FaReact, FaGamepad } from "react-icons/fa";
import { MdMobileFriendly } from "react-icons/md";
import { BiGlassesAlt } from "react-icons/bi";
import { GiGuitarBassHead } from "react-icons/gi";
import { EasterEggContext } from "../context/EasterEggProvider";

function Skills() {
  const { state: easterEgg } = React.useContext(EasterEggContext);
  return (
    <section className="bg-gray-50 dark:bg-gray-700 pt-20 pb-28 px-8">
      <div className="max-w-6xl mx-auto">
        <div className="text-center">
          <h2 className="text-5xl text-gray-800 dark:text-gray-200 font-bold">
            Skills
          </h2>
        </div>
        <div className="mt-24 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20">
          {!easterEgg && (
            <>
              <Card>
                <CgIfDesign className="text-5xl text-primary dark:text-red-700" />
                <h2 className="pt-3 font-bold text-2xl dark:text-gray-300">
                  UI/UX
                </h2>
                <p className="pt-3 dark:text-gray-300">
                  Not a certificate professional, but with some insights. <br />{" "}
                  Working with frontend since 2015 got me some experience
                  dealing with user experience and interface.
                </p>
              </Card>
              <Card>
                <FaReact className="text-5xl text-primary dark:text-red-700" />
                <h2 className="pt-3 font-bold text-2xl dark:text-gray-300">
                  React
                </h2>
                <p className="pt-3 dark:text-gray-300">
                  Felt in love with this technology at first sight. <br />
                  Been using this since 2017 and did not stopped loving since
                  then.
                </p>
              </Card>
              <Card>
                <MdMobileFriendly className="text-5xl text-primary dark:text-red-700" />
                <h2 className="pt-3 font-bold text-2xl dark:text-gray-300">
                  Mobile
                </h2>
                <p className="pt-3 dark:text-gray-300">
                  With the curiosity to learn React it was natural to learn
                  React Native as well. <br />
                  Since 2019 I've been working a long with web development and
                  mobile development using both technologies.
                </p>
              </Card>
            </>
          )}
          {easterEgg && (
            <>
              <Card>
                <FaGamepad className="text-5xl text-primary dark:text-red-700" />
                <h2 className="pt-3 font-bold text-2xl dark:text-gray-300">
                  Gaming
                </h2>
                <p className="pt-3 dark:text-gray-300">
                  Until 2020 I used to play professional gaming on{" "}
                  <a
                    href="https://playoverwatch.com"
                    target="_blank"
                    rel="noreferrer"
                    className="font-bold underline"
                  >
                    Overwatch
                  </a>
                  , an 6v6 hero shooter with a lot of things going on the
                  screen.
                </p>
              </Card>
              <Card>
                <BiGlassesAlt className="text-5xl text-primary dark:text-red-700" />
                <h2 className="pt-3 font-bold text-2xl dark:text-gray-300">
                  Geek
                </h2>
                <p className="pt-3 dark:text-gray-300">
                  Huge fan of the pop culture. <br />
                  Animes,TV shows, movies, you name it.
                </p>
              </Card>
              <Card>
                <GiGuitarBassHead className="text-5xl text-primary dark:text-red-700" />
                <h2 className="pt-3 font-bold text-2xl dark:text-gray-300">
                  Music
                </h2>
                <p className="pt-3 dark:text-gray-300">
                  Passionate for music. Love to play bass and have a pretty
                  ecletic taste for all genre, from k-pop to brazilian pop
                  songs.
                </p>
              </Card>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default Skills;
