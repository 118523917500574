import React, { useEffect, useState } from "react";
import up from "../assets/up.png";
import down from "../assets/down.png";
import left from "../assets/left.png";
import right from "../assets/right.png";
import b from "../assets/b.png";
import a from "../assets/a.png";
// import { Container } from './styles';

function ComboImage(props) {
  const { pressed } = props;
  const [img, setImg] = useState("");

  useEffect(() => {
    switch (pressed) {
      case "ArrowUp":
        setImg(up);
        break;
      case "ArrowDown":
        setImg(down);
        break;
      case "ArrowLeft":
        setImg(left);
        break;
      case "ArrowRight":
        setImg(right);
        break;
      case "b":
        setImg(b);
        break;
      case "a":
        setImg(a);
        break;
      default:
        break;
    }
  }, [pressed]);
  return (
    <div style={{ width: "80px", height: "80px" }}>
      <img src={img} alt="combo" className="rounded-lg" />
    </div>
  );
}

export default ComboImage;
